import { toCamelCase } from './caseConverter'

/**
 * Over the years we've produced barcodes on various formats and not used them.
 * Need backwards compatibility for these codes even if we create new ones on a standard format from now.
 * So keep the mess of unifying structure for these inside this module.
 * and always return on this format:
 *
 * {
 *   type           // f.ex. stock-position, tyre-hotel-line  *required
 *   id             // f.ex. 123                              *required
 *   <mixedField1>  // various fields that depend on type      optional
 *   <mixedField2>
 */

function parseJson(content) {
  //
  // Proper JSON contents
  //
  // F.ex: { "eontyre": true, "type": "stock-position", "id": 29510, "customer_id": 123 }
  // Means: { "type": "stock-position", "id": 29510, "customerId": 123 }
  //
  if (content.substr(0, 1) === '{' && content.substr(1, 1) === '"') {
    const data = JSON.parse(content)
    if (data && data.eontyre && data.type && data.id) {
      const barcode: any = {}
      for (const key in data) {
        if (key !== 'eontyre') {
          barcode[toCamelCase(key)] = data[key]
        }
      }
      return barcode
    } else {
      console.error('Unknown barcode(parseJson): ' + content)
    }
  }
}

function parseJsonIshLegacy(content) {
  //
  // Legacy JSON-ish contents
  //
  // F.ex: {eontyre:1,type:tyre-hotel-line-label,tyre_hotel_id:129,tyre_hotel_line_id:655,stock_position_item_id:null}
  // Means: { "type": "tyre-hotel-line", "id": 655, "tyreHotelId": 129 }
  //
  if (content.substr(0, 1) === '{' && content.substr(1, 1) !== '"') {
    const parts = content.substr(1, content.length - 2).split(',')
    const data: any = {}
    for (let i = 0; i < parts.length; i++) {
      const keyVal = parts[i].split(':')
      data[toCamelCase(keyVal[0].trim())] = keyVal[1].trim()
    }
    if (data && data.eontyre && data.type) {
      switch (data.type) {
      case 'tyre-hotel-line-label':
        return {
          type: 'tyre-hotel-line',
          id: data.tyreHotelLineId,
            tyreHotelId: data.tyreHotelId,
        }
      default:
        console.error('Unknown barcode(parseJsonIshLegacy1): ' + content)
      }
    } else {
      console.error('Unknown barcode(parseJsonIshLegacy1): ' + content)
    }
  } else if (content.substr(0, 1) === '{' && content.substr(1, 1) === '"') {
    const data = JSON.parse(content)
    // {"eontyre":1,"type":"tyre-hotel-line-label","tyre_hotel_id":2404,"tyre_hotel_line_id":18284,"stock_position_item_id":null}
    if (data && data.eontyre && data.type) {
      switch (data.type) {
      case 'tyre-hotel-line-label':
        return {
          type: 'tyre-hotel-line',
          id: data.tyre_hotel_line_id,
            tyreHotelId: data.tyre_hotel_id,
        }
      default:
        console.error('Unknown barcode(parseJsonIshLegacy1): ' + content)
      }
    } else {
      console.error('Unknown barcode(parseJsonIshLegacy1): ' + content)
    }
  }
}

function parseUrl(content) {
  //
  // URL contents
  //
  // F.ex: http://p154.eontyre.com/#29510%23/stock_position/labels/null
  // Means: type = stock-position, id = 29510
  //
  // Use indexOf(tp://)==2 instead of indexOf(http://)==0 to handle h|tp:// and xttp:// errors etc
  if (content.indexOf('tp://') === 2 || content.indexOf('tps://') === 2) {
    const parts = content.split('://')[1].split('/')

    let id = null
    let start = 0
    for (let i = 0; i < parts.length; i++) {
      const val = parts[i].replace(/%23/g, '#').replace(/^#/, '').replace(/#$/, '')
      if (val.match(/^\d+$/)) {
        start = i
        id = val
        break
      }
    }

    const indexModule = start + 1
    const indexView = start + 2

    const key = parts[indexModule].replace(/^mod_/, '') + '.' + parts[indexView] // module.view
    switch (key) {
    case 'stock_position.label':
    case 'stock_position.labels':
      return {
        type: 'stock-position',
          id: id,
      }
    default:
      console.error('Unknown barcode(parseUrl): ' + content)
    }
  }
}

export function parseBarCode(content) {
  let barcode: any = {}

  barcode = parseJson(content)
  if (!barcode) {
    barcode = parseJsonIshLegacy(content)
  }
  if (!barcode) {
    barcode = parseUrl(content)
  }
  if (barcode) {
    return barcode
  }

  console.error('Unknown barcode(sink): ' + content)
}
